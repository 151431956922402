// Import jQuery
import $ from 'jquery';
import { CONSOLE_LOG, CryptoJS_API_KEY, KEY_ENAM, PHP_SERVER_URL } from './constants';

export const HAS_EXTERNAL_PATH = hasExternalPathFunction();

if (CONSOLE_LOG) console.log("HAS_EXTERNAL_PATH:", HAS_EXTERNAL_PATH);



function hasExternalPathFunction() {
    const path = window.location.pathname;
    return path.split('/').length > 2;
}

// Function to shuffle the testimonials array
export function shuffleArrayObject(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getCurrentPageIndex() {
  // Get the current URL
  const currentUrl = window.location.href;

  // Parse the URL to get the path
  const url = new URL(currentUrl);
  const path = url.pathname;

  // Split the path into segments
  const segments = path.split('/').filter(segment => segment.length > 0);

  // Determine the current index
  const currentIndex = segments.length;

  // Output the result
  // if (CONSOLE_LOG) console.log(`CurrentURL: ${currentUrl}`);
  // if (CONSOLE_LOG) console.log(`CurrentIndex: ${currentIndex}`);
  
  return currentIndex;
}

export function modifyPageURL(pageUrl) {
  // Determine the current index
  const currentIndex = getCurrentPageIndex();
  if (CONSOLE_LOG) console.log("currentIndex - ", currentIndex);

  // Check if the URL should be skipped
  if (!shouldSkipUrl(pageUrl)) { // Fixed the 'url' to 'pageUrl' here
    if (currentIndex === 2) {
      pageUrl = "../../" + pageUrl;
    } else if (currentIndex === 3) {
      pageUrl = "../../" + pageUrl;
    } else {
      pageUrl = "../" + pageUrl;
    }
  }

  // Output the result
  //if (CONSOLE_LOG) console.log(`PageURL: ${pageUrl}`);
  return pageUrl;
}

// The `shouldSkipUrl` function definition should be outside or imported
export function shouldSkipUrl(url) {
  const skipPrefixes = ['https://', 'mailto:', 'tel:'];
  return skipPrefixes.some(prefix => url.startsWith(prefix));
}


// Encrypt data
export function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS_API_KEY).toString();
}

// Decrypt data
export function decryptData(encryptedData) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, CryptoJS_API_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
}

// Save data Local Storage with expiration
export function saveDataToLocalStorage(key, data, expireMinutes = 10) {
    const expirationTime = parseExpireTime(expireMinutes);
    if (!expirationTime) {
      console.error("Invalid expireTime format. Use '10m', '1h', or '7d'.");
      return;
    }
    const encryptedData = encryptData({ data, expirationTime });
    localStorage.setItem(key, encryptedData);
}

// Get Local Storage data and check expiration
export function getDataFromLocalStorage(key) {
    let encryptedData;
  
    if (key) {
        encryptedData = localStorage.getItem(key);
        // console.log("Data size:", calculateDataSize(encryptedData));
    } else {
      console.error("Invalid storage type specified.");
      return null;
    }
  
    if (!encryptedData) return null;
  
    const { data, expirationTime } = decryptData(encryptedData) || {};
    if (!expirationTime || Date.now() > expirationTime) {
        localStorage.removeItem(key);
        if(CONSOLE_LOG) console.warn("Data expired or invalid.");
        return null;
    }
  
    return data;
}

// Save data Session Storage with expiration
export function saveDataToSessionStorage(key, data, expireMinutes = 10) {
    const expirationTime = parseExpireTime(expireMinutes);
    if (!expirationTime) {
      console.error("Invalid expireTime format. Use '10', '1h', or '7d'.");
      return;
    }

    const encryptedData = encryptData({ data, expirationTime });
    sessionStorage.setItem(key, encryptedData);
}

// Get Session Storage data and check expiration
export function getDataFromSessionStorage(key) {
    let encryptedData;
  
    if (key) {
        encryptedData = sessionStorage.getItem(key);
    } else {
      console.error("Invalid storage type specified.");
      return null;
    }
  
    if (!encryptedData) return null;
  
    const { data, expirationTime } = decryptData(encryptedData) || {};
    if (!expirationTime || Date.now() > expirationTime) {
        sessionStorage.removeItem(key);
        if(CONSOLE_LOG) console.warn("Data expired or invalid.");
        return null;
    }
  
    return data;
}

// Save data to Cookies with expiration
export function saveDataToCookies(key, data, expireTime = "10m") {
    // Parse the expiration time
    const expirationTime = parseExpireTime(expireTime);
    
    if (!expirationTime) {
        console.error("Invalid expireTime format. Use formats like '10m', '1h', '2d'.");
        return;
    }

    // Encrypt the data
    const encryptedData = encryptData({ data, expirationTime });
    if (!encryptedData) {
        console.error("Encryption failed. Ensure `encryptData` is correctly implemented.");
        return;
    }

    // Format the expiration date for the cookie
    const expireDate = new Date(expirationTime).toUTCString();

    // Attempt to save the cookie
    document.cookie = `${key}=${encodeURIComponent(encryptedData)}; expires=${expireDate}; path=/`;
}

// Get Cookies data and check expiration
export function getDataFromCookies(key) {
    if (!key) {
        console.error("Key is required to fetch data from cookies.");
        return null;
    }

    // Extract the cookie value for the given key
    const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith(`${key}=`))
        ?.split("=")[1];

    if (!cookieValue) {
        if(CONSOLE_LOG) console.warn(`No cookie found with the key: ${key}`);
        return null;
    }

    // Decode the cookie value
    const encryptedData = decodeURIComponent(cookieValue);

    // Decrypt the cookie data
    const decryptedData = decryptData(encryptedData);
    if (!decryptedData) {
        if(CONSOLE_LOG) console.warn(`Failed to decrypt data for key: ${key}`);
        return null;
    }

    const { data, expirationTime } = decryptedData;
    const currentTimeNow = Date.now();

    // Check if the data has expired
    if (!expirationTime || currentTimeNow > expirationTime) {
        // Delete the expired cookie
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        if(CONSOLE_LOG) console.warn(`Cookie for key '${key}' has expired.`);
        return null;
    }

    // Return the decrypted data
    return data;
}

// Helper function to parse expiration time
function parseExpireTime(expireTime) {
    const now = Date.now();
  
    // If the input is a normal number, treat it as milliseconds and return directly
    if (typeof expireTime === "number") {
        return now + expireTime;
    }
  
    // Match combined units like "1h30m" or single units like "10m"
    const match = expireTime.match(/(\d+[mhd])/g); // Extracts all unit-value pairs (e.g., "1h", "30m")
    if (!match) return null;
  
    let totalMilliseconds = 0;
  
    for (const part of match) {
        const value = parseInt(part.slice(0, -1), 10); // Extract the numeric value
        const unit = part.slice(-1); // Extract the unit (m, h, or d)
  
        switch (unit) {
            case "h": // Hours
                totalMilliseconds += value * 60 * 60 * 1000;
                break;
            case "d": // Days
                totalMilliseconds += value * 24 * 60 * 60 * 1000;
                break;
            case "m": // Minutes
                totalMilliseconds += value * 60 * 1000;
                break;
            default:
                totalMilliseconds += value * 60 * 1000;
        }
    }
  
    return now + totalMilliseconds;
}

export function calculateDataSize(data) {
    // Convert data to a string (e.g., for objects or arrays)
    const dataString = typeof data === "string" ? data : JSON.stringify(data);

    // Calculate byte size (UTF-16 encoding, 2 bytes per character)
    const byteSize = dataString.length * 2;

    // Convert to KB and MB
    const sizeInKB = byteSize / 1024;
    const sizeInMB = sizeInKB / 1024;

    return {
        Size_B: byteSize,
        Size_KB: sizeInKB,
        Size_MB: sizeInMB
    };
}


export function hidePreloader(){
    // Select the element with the class 'preloader'
    const preloader = document.querySelector('.preloader');

    // Apply a fade-out effect
    preloader.style.transition = 'opacity 0.5s ease'; // Set transition for fade-out
    preloader.style.opacity = 0; // Fade out by setting opacity to 0

    // After the fade-out, hide the element
    setTimeout(() => {
        preloader.style.display = 'none'; // Hide the element after fading out
    }, 500); // Match the timeout duration with the fade-out duration
}


export async function fetchDataFromPHP(reqMethod, endPoints, formObject) {
    try {
        // Determine the method and body based on reqMethod
        const method = reqMethod === 1 ? 'POST' : 'GET';
        const body = reqMethod === 1 ? JSON.stringify(formObject) : undefined;

        const response = await fetch(`${PHP_SERVER_URL}${endPoints}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 65dd356c8096259b546cb14cf9639cc54604444ff0ee8dc5d9b27243954e401a' // Add your token if necessary
            },
            body: body // Only include body if it's defined
        });

        const data = await response.json();
        if (CONSOLE_LOG) console.log('Response from PHP:', data);

        if (data.status === 200) {
            return data;
        } else {
            alert(data.message);
        }
        return false;
    } catch (error) {
        console.error('Error:', error);
        alert('There was an error. Kindly try again after some time.');
        return false;
    }
}


async function submitForm() {
    const endPoints = '/php/create-checkout-url.php';
    const formObject = {
        field1: 'value1',
        field2: 'value2'
    };

    const result = await fetchDataFromPHP(1, endPoints, formObject);

    if (result) {
        console.log('Form submitted successfully:', result);
    } else {
        console.log('Form submission failed.');
    }
}

//submitForm();


// Function to create and display the modal
function createAndShowModal(title, body, btns) {
      // Start building the button HTML
      let buttonsHtml = '';

      // Check if t1 exists and add the primary button
      if (btns.t1) {
          buttonsHtml += `
              <button type="button" class="btn btn-primary" id="primaryButton">${btns.t1}</button>
          `;
      }

      // Check if t2 exists and add the secondary button
      if (btns.t2) {
          buttonsHtml += `
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="secondaryButton">${btns.t2}</button>
          `;
      }

      // Create the modal HTML
      const modalHtml = `
          <div class="modal fade" id="dynamicModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="dynamicModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h1 class="modal-title fs-5" id="dynamicModalLabel">${title}</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          ${body}
                      </div>
                      <div class="modal-footer">
                          ${buttonsHtml}
                      </div>
                  </div>
              </div>
          </div>
      `;

      // Append modal to the body
      $('body').append(modalHtml);

      // Show the modal using Bootstrap's jQuery API
      const modalElement = $('#dynamicModal');
      modalElement.modal('show');

      // Handle primary button click
      $('#primaryButton').on('click', function () {
          if (btns.t1Link !== 0) {
              window.location.href = btns.t1Link; // Redirect to the specified link
          } else {
              modalElement.modal('hide'); // Just hide the modal if no link is provided
          }
      });

      // Remove the modal from DOM when it is closed
      modalElement.on('hidden.bs.modal', function () {
          modalElement.remove();
      });
}

// Trigger the modal
//createAndShowModal('Form Submission Successful', 'Your form has been submitted successfully!', { t1: "Okay", t1Link: 0 });


export function removeFirstOccurrence(arr, value) {
    // Find the index of the value in the array
    const index = arr.indexOf(value);

    // Check if the value exists in the array
    if (index !== -1) {
        // Remove the value at the found index
        arr.splice(index, 1);
    }

    // Return the modified array
    return arr;
}

export function popupSideMenu($sideMenu, $sideMunuOpen, $sideMenuCls, $toggleCls) {
    // Sidebar Popup
    $($sideMunuOpen).on('click', function (e) {
        e.preventDefault();
        $($sideMenu).addClass($toggleCls);
        const course_cart_array = getDataFromCookies(KEY_ENAM.cart_array) || [];
        renderCartDetails(course_cart_array);
    });
    $($sideMenu).on('click', function (e) {
        e.stopPropagation();
        $($sideMenu).removeClass($toggleCls)
    });
    var sideMenuChild = $sideMenu + ' > div';
    $(sideMenuChild).on('click', function (e) {
        e.stopPropagation();
        $($sideMenu).addClass($toggleCls)
    });
    $($sideMenuCls).on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $($sideMenu).removeClass($toggleCls);
    });
};
popupSideMenu('.sidemenu-wrapper', '.sideMenuToggler', '.sideMenuCls', 'show');


export function changeCartCounter(cart_array){
    if (CONSOLE_LOG) console.log("course_cart_array:", cart_array);
    // const cart_array = getDataFromCookies(KEY_ENAM.cart_array) || [];
    const cartCounterEl = document.querySelector(".cart-counter-badge");
    if(cartCounterEl){
        cartCounterEl.textContent = cart_array.length;
    }
}

export async function renderCartDetails(courseCartArray){
    var courseData = getDataFromLocalStorage(KEY_ENAM.courses) || [];
    if (CONSOLE_LOG) console.log("Course Data:", courseData);

    if(courseData.length == 0){
        const result = await fetchDataFromPHP(1, '/php/get_courses.php');

        if (result.data && result.data.length > 0) {
            if(CONSOLE_LOG) console.log('Data Received:', result.data);
            if(result.data){
                courseData = result.data;
                saveDataToLocalStorage(KEY_ENAM.courses, result.data, "15m")
            }
        }
    }

    // Call the function
    const data = filterCourses(courseData, courseCartArray) || [];
    if (CONSOLE_LOG) console.log("Filter Course Data:", data);
    populateCartItems(courseCartArray, data);
}

export function populateCartItems(courseCartArray, data) {
    // Select the cart container and its elements
    const cartContainer = document.getElementById('cart_items_list');
    const emptyCartContent = cartContainer.querySelector('.empty-cart-content');
    const cartList = cartContainer.querySelector('.cart_list');
    const subtotalElement = document.getElementById("subtotal");
    // var subtotalValue = parseFloat(subtotalElement.textContent.replace(/[^0-9.-]+/g, ""));
    // console.log("Subtotal Value:", subtotalValue); // Output: 0
    var subtotalValue = 0;


    // Clear the existing cart list
    cartList.innerHTML = '';

    if (data.length === 0) {
        // Show empty cart message if no items
        emptyCartContent.style.display = 'flex';
        cartList.style.display = 'none';
    } else {
        // Hide empty cart message if there are items
        emptyCartContent.style.display = 'none';
        cartList.style.display = 'block';

        // Loop through data and add items to the cart list
        data.forEach(course => {
            const listItem = document.createElement('li');
            listItem.classList.add('mini_cart_item');
            listItem.setAttribute('data-course-id', course.course_id);

            // Remove button
            const removeButton = document.createElement('a');
            removeButton.href = '#';
            removeButton.classList.add('remove', 'remove_from_cart_button');
            removeButton.innerHTML = '<i class="fal fa-trash-alt"></i>';
            
            // Handle item removal
            removeButton.addEventListener('click', (e) => {
                e.preventDefault();
                listItem.remove();
                
                courseCartArray = removeFirstOccurrence(courseCartArray, course.course_id);
                const button = document.querySelector(`button[data-course-id="${course.course_id}"]`);
                if (button) {
                    button.textContent = "Add to Cart";
                    button.setAttribute('data-cart-in', "false");
                } 
                const addCartbutton = document.querySelector(`#add-cart-to-course-btn`);
                if (addCartbutton) {
                    addCartbutton.innerHTML = `<i class="fas fa-shopping-bag me-3"></i> Add to cart`;
                }

                console.log(`Removed course with ID: ${course.course_id}`, courseCartArray);
                saveDataToCookies(KEY_ENAM.cart_array, courseCartArray, "30d");
                changeCartCounter(courseCartArray);
                subtotalValue = subtotalValue - course.price;
                subtotalElement.textContent = `$${subtotalValue}`;
                // Refresh the cart to check if it's empty
                const remainingItems = cartList.querySelectorAll('.mini_cart_item');
                if (remainingItems.length === 0) {
                    populateCartItems(courseCartArray, []);
                }
            });
            const currentDomain = `${window.location.protocol}//${window.location.host}`;
            // Course link
            const courseLink = document.createElement('a');
            courseLink.href = `${currentDomain}/course/details.html?id=${course.course_id}`;

            // Thumbnail image
            const thumbnail = document.createElement('img');
            thumbnail.src = course.banner_url;
            thumbnail.alt = `Course Id: #${course.course_id}`;
            courseLink.appendChild(thumbnail);

            // Course title
            const title = document.createElement('span');
            title.classList.add('cart-title');
            title.textContent = course.name;
            courseLink.appendChild(title);

            // Price
            const price = document.createElement('p');
            price.classList.add('amount');
            price.setAttribute('data-amount', `$${course.price}`);
            price.textContent = `$${course.price}.00`;
            
            subtotalValue = subtotalValue + course.price;
            subtotalElement.textContent = `$${subtotalValue}`;

            // Append all elements to list item
            listItem.appendChild(removeButton);
            listItem.appendChild(courseLink);
            listItem.appendChild(price);

            // Append the list item to the cart list
            cartList.appendChild(listItem);
        });
    }


}

export function filterCourses(courseData, courseCartArray) {
    // Return an empty array if the courseCartArray is empty
    if (courseCartArray.length === 0) {
        return [];
    }

    // Filter courseData where course_id exists in courseCartArray
    return courseData.filter(course => courseCartArray.includes(course.course_id));
}
