import 'magnific-popup/dist/magnific-popup.css';
import 'jquery-ui/themes/base/theme.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import "slick-carousel";
import 'magnific-popup';
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';
import counterUp from 'counterup2';
import 'jquery-waypoints/waypoints.js';
import { changeCartCounter, fetchDataFromPHP, getDataFromCookies, getDataFromLocalStorage, hidePreloader, renderCartDetails, saveDataToCookies, saveDataToLocalStorage } from './module';
import { CONSOLE_LOG, COOKIE_API_KEY, KEY_ENAM } from './constants';

var COURSE_DATA = [
    {
        id: 1,
        course_id: "ecb6d3d29a",
        name: "Workplace Mental Health: For Employees",
        overview: "Observe some important methods for creating and maintaining a safe and healthy workplace for all members of the organization. The health and wellbeing of workers is inextricably linked to their mental health and wellbeing, therefore it is critical that everyone understands the fundamentals of Mental Fitness, Mental Distress and Mental Injury, Environment and Stigma, as well as Providing Support and much more. Preserving yours and your colleagues’ mental health and wellbeing in the workplace is vital for personal performance and the successful operation of the organization as a whole.",
        org_objective: [
            "Build and Maintain A Psychologically Healthy Workplace",
            "Promote a Duty of Care for All Members of the Organization",
            "Create a High Standard of Understanding and Support",
            "Develop a Compassionate, Thoughtful and Respectful Work Culture",
            "Reduce Harm of Mental Health Stigma, Injury, Illness, and Distress in Workplace",
            "Helps Mitigate the Risk of Litigation",
            "Photo Verification Provides Proof of Training",
            "Self-pacing Provides Proof that Learners Understood Training",
            "Satisfaction that Program is developed in line with CCOHS Standards"
        ],
        learning_objective: [
            "Establish a Good Mental Health Environment",
            "Encourage Mental Health Awareness in the Organization",
            "Understand Mental Fitness and Positive Lifestyle Habits",
            "Affect Positive Change in the Work Culture",
            "Effectively Determine Mental Distress and Mental Injury",
            "Promote a Psychologically Healthy Workplace",
            "Reduce Mental Health Stigma",
            "Efficiently Provide Support and Offer Communication Strategies",
            "Successfully Learn how to Help Yourself and Performance"
        ],
        topics: [
            "Awareness",
            "Environment and Stigma",
            "Mental Fitness",
            "Mental Fitness – The Ten Lifestyle Habits",
            "Helping Yourself",
            "Helping Performance",
            "Psychologically Healthy Workplaces",
            "Mental Distress and Mental Injury",
            "Communication Strategies",
            "Providing Support"
        ],
        audience: [
            "New Employees and Members of the Organization",
            "Workers and Leaders at all Organizational Levels",
            "Experienced Professionals Looking to Advance or Update Skills"
        ],
        price: 35,
        rating: 4.8,
        rating_count: 1669,
        lessons: [
            "Awareness",
            "Environment and Stigma",
            "Creating A Supportive Workplace",
            "Workplace Plans",
            "Mental Health Program",
            "Helping Workers’ Performance",
            "Psychologically Healthy Workplaces",
            "Mental Distress and Mental Injury",
            "Communication Strategies",
            "Providing Support"
        ],
        duration: 30,
        course_level: ["Beginner"],
        category: "Leadership",
        banner_url: "https://host.safetytraining.education/resources/courses/ca_workplace%20mental%20health%20employees.jpg"
    },
    {
        id: 2,
        course_id: "ecb88c489a",
        name: "Workplace Mental Health: For Managers",
        overview: "Examine some important methods for creating and maintaining a safe and healthy workplace for all members of the organization. The health and wellbeing of workers is inextricably linked to their mental health and wellbeing, therefore it is crucial that all managers understand the fundamentals of Creating A Supportive Workplace, Mental Health Programs, as well as Psychologically Healthy Workplaces and much more. Preserving your workers’ mental health and wellbeing in the workplace is vital for their personal performance and the successful operation of the organization as a whole.",
        org_objective: [
            "Build and Maintain A Psychologically Healthy Workplace",
            "Promote a Duty of Care for All Members of the Organization",
            "Create a High Standard of Understanding and Support",
            "Develop a Compassionate, Thoughtful and Respectful Work Culture",
            "Reduce Harm of Mental Health Stigma, Injury, Illness, and Distress in Workplace",
            "Helps Mitigate the Risk of Litigation",
            "Photo Verification Provides Proof of Training",
            "Self-pacing Provides Proof that Learners Understood Training",
            "Satisfaction that Program is developed in line with CCOHS Standards"
        ],
        learning_objective: [
            "Establish a Good Mental Health Environment",
            "Encourage Mental Health Awareness in the Organization",
            "Develop and Implement Mental Health Programs and Workplace Plans",
            "Affect Positive Change in the Work Culture",
            "Effectively Determine Mental Distress and Mental Injury",
            "Promote a Psychologically Healthy and Supportive Workplace",
            "Reduce Mental Health Stigma",
            "Efficiently Provide Support and Offer Communication Strategies",
            "Successfully Learn how to Help Your Workers’ Performance"
        ],
        topics: [
            "Awareness",
            "Environment and Stigma",
            "Creating A Supportive Workplace",
            "Workplace Plans",
            "Mental Health Program",
            "Helping Workers’ Performance",
            "Psychologically Healthy Workplaces",
            "Mental Distress and Mental Injury",
            "Communication Strategies",
            "Providing Support"
        ],
        audience: [
            "New Employees and Members of the Organization",
            "Workers and Leaders at all Organizational Levels",
            "Experienced Professionals Looking to Advance or Update Skills"
        ],
        price: 35,
        rating: 4.4,
        rating_count: 1590,
        lessons: [
            "Awareness",
            "Environment and Stigma",
            "Mental Fitness",
            "Mental Fitness – The Ten Lifestyle Habits",
            "Helping Yourself",
            "Helping Performance",
            "Psychologically Healthy Workplaces",
            "Mental Distress and Mental Injury",
            "Communication Strategies",
            "Providing Support"
        ],
        duration: 30,
        course_level: ["Beginner"],
        category: "Leadership",
        banner_url: "https://host.safetytraining.education/resources/courses/ca_workplace%20mental%20health%20for%20managers.jpg"
    },
    {
        id: 3,
        course_id: "3e66edb79b",
        name: "Health and Hygiene in the Office",
        overview: "Analyze the fundamental safety measures for maintaining a healthy and hygienic workplace...",
        org_objective: [
            "Creates Health and Hygiene Conscious Workers",
            "Decreases Risk and Incidence of Illness and Infection from Poor Hygiene Practices",
            "Encourages Higher Health and Hygiene Standards Across the Organization",
            "Develops a Culture of Respect for Health and Hygiene",
            "Helps Mitigate the Risk of Litigation",
            "Photo Verification Provides Proof of Training",
            "Self-pacing Provides Proof that Learners Understood Training",
            "Satisfaction that Program is developed in line with CCOHS Standards"
        ],
        learning_objective: [
            "Confidently and Comfortably Maintain and Clean Work Environment",
            "Understand Positive Food and Preparations for Health",
            "Successfully Maintain Personal and Food Hygiene",
            "Exhibit and Encourage Positive Health and Hygiene Practices at Work",
            "Demonstrate Strong Understanding of Health and Hygiene Importance",
            "Effectively Maintain Personal Health and Fitness",
            "Reduce Incidence of Sickness and Infections Spreading"
        ],
        topics: [
            "How Infections Spread",
            "Infectious Diseases",
            "Cleanliness at Work",
            "Personal Hygiene",
            "Food Hygiene",
            "Health and Fitness",
            "Food for Health"
        ],
        audience: [
            "Workers and Leaders at all Organizational Levels",
            "Experienced Professionals Looking to Advance or Update Skills"
        ],
        price: 30,
        rating: 4.5,
        rating_count: 1450,
        lessons: [
            "How Infections Spread",
            "Infectious Diseases",
            "Cleanliness at Work",
            "Personal Hygiene",
            "Food Hygiene",
            "Health and Fitness",
            "Food for Health"
        ],
        duration: 30,
        course_level: ["Beginner"],
        category: "Health and Safety",
        banner_url: "https://host.safetytraining.education/resources/courses/health%20and%20hygiene%20in%20the%20office.jpg"
    },
];

var COURSE_DATA2 = [
    {
        id: 134,
        course_id: "ecb6d3d29a",
        name: "Workplace Mental Health: For Employees",
        price: 35,
        rating: 4.8,
        rating_count: 1669,
        duration: 30,
        course_level: ["Beginner"],
        category: "Leadership",
        banner_url: "https://host.safetytraining.education/resources/courses/ca_workplace%20mental%20health%20employees.jpg",
        demo_video_url: 0,
        priority: 2,
    },
    {
        id: 114,
        course_id: "ecb6d3d29a",
        name: "Workplace Mental Health: For Employees",
        price: 35,
        rating: 4.8,
        rating_count: 1669,
        duration: 30,
        course_level: ["Beginner"],
        category: "Leadership",
        banner_url: "https://host.safetytraining.education/resources/courses/ca_workplace%20mental%20health%20employees.jpg",
        demo_video_url: 0,
        priority: 1,
    },
    {
        id: 234,
        course_id: "ecb6d3d29a",
        name: "Workplace Mental Health: For Employees",
        price: 35,
        rating: 4.8,
        rating_count: 1669,
        duration: 30,
        course_level: ["Beginner"],
        category: "Leadership",
        banner_url: "https://host.safetytraining.education/resources/courses/ca_workplace%20mental%20health%20employees.jpg",
        demo_video_url: 0,
        priority: 2,
    },
];

hidePreloader();

var COURSE_DATA_FILTERED = [];
// Constants for Pagination
const coursesPerPage = 6;  // Adjust based on desired items per page
let currentPage = 1;

const renderCourseElements = async (rawdata) => {
    const data = rawdata.sort((a, b) => a.priority - b.priority);
    COURSE_DATA = data;
    COURSE_DATA_FILTERED = data;
    var allCategories = ["All"];
    const uniqueCategories = getUniqueCategories(data);
    allCategories = allCategories.concat(uniqueCategories);
    if(CONSOLE_LOG) console.log("All Categories:", allCategories);
    generateTabs(allCategories);
    // Initial Render
    renderCourses(currentPage);
    setupPagination();
}

async function fetchCourseData() {
    const courseData = getDataFromLocalStorage(KEY_ENAM.courses);
    if(!courseData){
        const result = await fetchDataFromPHP(1, '/php/get_courses.php');

        if (result.data && result.data.length > 0) {
            if(CONSOLE_LOG) console.log('Data Received:', result.data);
            if(result.data){
                saveDataToLocalStorage(KEY_ENAM.courses, result.data, "15m")
                renderCourseElements(result.data);
            }
        } else {
            if(CONSOLE_LOG) console.log('Courses Data Fected failed.');
        }
    } else {
        if(CONSOLE_LOG) console.log('Data Retrive from Local Storage:', courseData);
        renderCourseElements(courseData);
    }
    
}
fetchCourseData();

function getUniqueCategories(courses) {
    const categories = [];
    
    courses.forEach(course => {
        // Check if category already exists in the categories array
        if (!categories.includes(course.category)) {
            categories.push(course.category);
        }
    });
    
    return categories;
}

// Function to create a tab button
function createTabButton(category, isActive) {
    const button = document.createElement('button');
    button.type = 'button';
    button.className = 'tab-btn';
    button.id = `tab-${category.toLowerCase().replace(/\s+/g, '-')}`;
    button.dataset.bsToggle = 'tab';
    button.dataset.bsTarget = `#tab-content-${category.toLowerCase().replace(/\s+/g, '-')}`;
    button.role = 'tab';
    button.setAttribute('aria-controls', `tab-content-${category.toLowerCase().replace(/\s+/g, '-')}`);
    button.setAttribute('aria-selected', isActive ? 'true' : 'false');
    button.classList.toggle('active', isActive);
    button.textContent = category;
    return button;
}

// Function to generate the tab list
function generateTabs(categories) {
    const tabList = document.getElementById('tablist');
    tabList.innerHTML = ''; // Clear any existing content

    categories.forEach((category, index) => {
        const isActive = index === 0; // Make the first tab active by default
        const tabButton = createTabButton(category, isActive);
        tabList.appendChild(tabButton);
    });
}

// Function to Generate HTML for Each Course
function createCourseHTML(course) {
    const course_cart_array = getDataFromCookies(KEY_ENAM.cart_array) || [];
    const courseInCart = course_cart_array.includes(course.course_id);

    const topicsData = JSON.parse(course.topics);
    const topics = topicsData.map(topic => `<i class="fal fa-circle"></i>${topic}`).join(" ");
    const tabContentId = 'tab-content-' + course.category.toLowerCase().replace(/\s+/g, '-');
    return `
        <div class="col-md-6 col-xl-4 tab-item ${tabContentId}">
            <div class="course-box style2" data-course-id="${course.course_id}" data-course-price="${course.price}">
                <div class="course-img">
                    <img src="${course.banner_url}" alt="${course.name}">
                    <span class="tag"><i class="fas fa-dollar"></i>${course.price}</span>
                </div>
                <div class="course-content">
                    <div class="course-rating">
                        <div class="star-rating" role="img" aria-label="Rated ${course.rating} out of 5">
                            <span style="width:${(course.rating / 5) * 100}%"></span>
                        </div>(${course.rating})
                    </div>
                    <h3 class="course-title"><a href="./course/details.html?id=${course.course_id}">${course.name}</a></h3>
                    <div class="course-meta">
                        <p class="max-lines-3 m-0"><span class="semi-bold">Topics:</span><span>${topics}</span></p>
                    </div>
                    <div class="course-author">
                        <div class="category">${course.category}</div>
                        <button type="button" class="buy-now-btn" data-course-id="${course.course_id}" data-cart-in="${courseInCart}">${courseInCart ? "View Cart" : "Add to Cart"}</button>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function renderCourses(page, activeButtonText = "All") {
    // Reset current page to the specified page
    currentPage = page;

    // Filter based on the active tab category
    COURSE_DATA_FILTERED = activeButtonText === "All"
        ? COURSE_DATA
        : COURSE_DATA.filter(course => course.category === activeButtonText);

    // Check if there are courses to display after filtering
    if (COURSE_DATA_FILTERED.length === 0) {
        // Handle case when there are no courses matching the filter
        const container = document.getElementById('course-container');
        if (container) {
            container.innerHTML = '<p>No courses available for the selected category.</p>';
        }

        // Update pagination count to show zero results
        const resultCount = document.querySelector('.woocommerce-result-count');
        if (resultCount) {
            resultCount.innerHTML = `Showing <span class="text-theme">0 courses</span> Of 0 Result`;
        }
        return; // Exit early as there is nothing to paginate or render
    }

    // Paginate the filtered data
    const startIndex = (page - 1) * coursesPerPage;
    const endIndex = startIndex + coursesPerPage;
    const paginatedCourses = COURSE_DATA_FILTERED.slice(startIndex, endIndex);

    // Render courses
    const container = document.getElementById('course-container');
    if (container) {
        container.innerHTML = paginatedCourses.map(createCourseHTML).join("");
    }


    // Update Pagination Count
    const resultCount = document.querySelector('.woocommerce-result-count');
    if (resultCount) {
        resultCount.innerHTML = `
            Showing <span class="text-theme">${paginatedCourses.length} courses</span> Of ${COURSE_DATA_FILTERED.length} Result
        `;
    }

    // const courseURL = `${window.location.protocol}//${window.location.host}/course/details.html?id=${courseId}`;
}

document.addEventListener('click', async (event) => {
    // Check if the clicked element is a 'buy-now-btn'
    if (event.target.classList.contains('buy-now-btn')) {
        const courseId = event.target.getAttribute('data-course-id');
        let course_cart_array = getDataFromCookies(KEY_ENAM.cart_array) || [];

        // Determine the current state based on the 'data-cart-in' attribute
        const isViewingCart = event.target.getAttribute('data-cart-in') === "true";

        if (isViewingCart) {
            // Handle "View Cart" logic
            console.log(`Button clicked: View Cart for courseId ${courseId}`);
            document.querySelector(".sidemenu-wrapper").classList.add("show");
            renderCartDetails(course_cart_array);
            
        } else {
            // Set the attribute to mark the button as in "View Cart" mode
            event.target.setAttribute('data-cart-in', "true");

            // Handle "Buy Now" logic
            console.log(`Button clicked: Buy Now for courseId ${courseId}`);
            
            // Update the button to reflect "View Cart" for this course
            event.target.innerHTML = `View Cart`;

            // Add course to the cart if not already present
            if (!course_cart_array.includes(courseId)) {
                course_cart_array.push(courseId);
                if (CONSOLE_LOG) console.log("Course Cart Array updated:", course_cart_array);
            }

            // Save updated cart and update the counter
            saveDataToCookies(KEY_ENAM.cart_array, course_cart_array, "30d");
            changeCartCounter(course_cart_array);

            if (CONSOLE_LOG) console.log("Final Course Cart Array:", course_cart_array);
        }
    }
});

// Function to Setup Pagination Controls
function setupPagination() {
    const totalPages = Math.ceil(COURSE_DATA_FILTERED.length / coursesPerPage);
    const paginationContainer = document.querySelector('.th-pagination ul');

    // Clear existing pagination links
    paginationContainer.innerHTML = '';

    // Previous Page Button
    const prevPage = document.createElement('li');
    prevPage.innerHTML = `<a><i class="far fa-arrow-left"></i></a>`;
    prevPage.addEventListener('click', () => changePage(Math.max(currentPage - 1, 1)));
    paginationContainer.appendChild(prevPage);

    // Page Number Buttons with Active Class
    for (let i = 1; i <= totalPages; i++) {
        const pageItem = document.createElement('li');
        pageItem.innerHTML = `<a >${String(i).padStart(2, '0')}</a>`;

        // Add active class to the current page
        if (i === currentPage) {
            pageItem.classList.add('active');
        }

        pageItem.addEventListener('click', () => changePage(i));
        paginationContainer.appendChild(pageItem);
    }

    // Next Page Button
    const nextPage = document.createElement('li');
    nextPage.innerHTML = `<a><i class="far fa-arrow-right"></i></a>`;
    nextPage.addEventListener('click', () => changePage(Math.min(currentPage + 1, totalPages)));
    paginationContainer.appendChild(nextPage);

}

window.changePage = function(page) {
    const totalPages = Math.ceil(COURSE_DATA_FILTERED.length / coursesPerPage);
    if (page >= 1 && page <= totalPages) {
        currentPage = page;
        const activebtntxt = getActiveTab();
        renderCourses(currentPage, activebtntxt);
        setupPagination();

        // Ensure DOM is updated before scrolling
        setTimeout(() => {
            const section = document.getElementById('courses');
            console.log("section:", section);
            if (section) {
                section.scrollIntoView({
                    behavior: 'smooth', // Smooth scrolling
                    block: 'start'     // Align to the top of the section
                });
            } else {
                console.error("Section '.courses' not found!");
            }
        }, 100); // Adjust timeout if necessary
    }
};

// Function to get the active tab button and its text
function getActiveTab() {
    const activeButton = document.querySelector('.tab-btn.active'); // Find the active button
    if (activeButton) {
        const activeButtonText = activeButton.textContent; // Get the text content of the active button
        if(CONSOLE_LOG) console.log('Active Button:', activeButton);
        if(CONSOLE_LOG) console.log('Active Button Text:', activeButtonText);
        return activeButtonText;
    } else {
        if(CONSOLE_LOG) console.log('No active button found');
        return null;
    }
}


document.addEventListener("DOMContentLoaded", function() {
    const tabList = document.getElementById("tablist");
    const courseContainer = document.getElementById("course-container");

    // Event listener for tab buttons
    tabList.addEventListener("click", function(event) {
        const clickedTab = event.target;

        // Check if the clicked element is a tab button
        if (clickedTab.classList.contains("tab-btn")) {
            currentPage = 1;
            const tabListButtonText = clickedTab.textContent;
            if(CONSOLE_LOG) console.log("tabListButtonText:", tabListButtonText);
            renderCourses(currentPage, tabListButtonText);
            setupPagination();
            // Remove the active class from all tab buttons
            document.querySelectorAll(".tab-btn").forEach(btn => btn.classList.remove("active"));
            
            // Add active class to the clicked tab
            clickedTab.classList.add("active");
            clickedTab.classList.remove("hidden");

            // Get the target content class from the data-bs-target attribute
            const targetClass = clickedTab.dataset.bsTarget.replace("#tab-content-", "");

            // Show all courses if "All" is clicked
            if (targetClass === "all") {
                document.querySelectorAll("#course-container > div").forEach(course => {
                    course.classList.add("active");
                    course.classList.remove("hidden");
                });
            } else {
                // Otherwise, only show courses that match the target class
                document.querySelectorAll("#course-container > div").forEach(course => {
                    if (course.classList.contains(`tab-content-${targetClass}`)) {
                        course.classList.add("active");
                        course.classList.remove("hidden");
                    } else {
                        course.classList.remove("active");
                        course.classList.add("hidden");
                    }
                });
            }
        }
    });
});

const sideMenuWrapper = document.querySelector('.sidemenu-wrapper');

sideMenuWrapper.addEventListener('transitionend', () => {
  if (sideMenuWrapper.classList.contains('show')) {
    document.body.classList.add('sidemenu-active');
  } else {
    document.body.classList.remove('sidemenu-active');
  }
});
